import React from "react"
import { graphql } from "gatsby"
//components
import ServicePageFr from "../components/Query/Services/ServicePageFr"
import ServicePageNl from "../components/Query/Services/ServicePageNl"
import SEO from "../components/SEO"

//css
import "../css/servicePage.css"
const ServicesPage = ({ pageContext, data }) => {
  return (
    <>
      {data.c.metaDsc ? (
        <SEO
          title={data.c.title}
          dsc={data.c.metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}
      <div className="servicePage">
        {pageContext.node_locale === "fr" ? (
          <ServicePageFr />
        ) : (
          <ServicePageNl />
        )}
      </div>
    </>
  )
}
export const query = graphql`
  query($contentful_id: String, $node_locale: String) {
    c: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      metaDsc {
        metaDsc
      }
    }
  }
`
export default ServicesPage
