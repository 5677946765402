import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
import Section from "../../Section"
//data
const getData = graphql`
  {
    c: allContentfulService(
      filter: { node_locale: { eq: "fr" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        icon {
          svg {
            content
          }
        }
        slug
        title
        node_locale
        resume {
          resume
        }
      }
    }
  }
`
const ServicePage = () => {
  const data = useStaticQuery(getData)
  return (
    <Section classname="pageService" container>
      {data.c.nodes.map((item, index) => {
        return (
          <div key={index} className="homeServiceElement">
            <div
              className="icon"
              dangerouslySetInnerHTML={{ __html: item.icon.svg.content }}
            />
            <div className="content">
              <h3>{item.title}</h3>
              <p>{item.resume.resume}</p>
              <CustomLink
                to={`/${item.node_locale}${item.slug}`}
                classname="button"
              >
                en savoir plus
              </CustomLink>
            </div>
          </div>
        )
      })}
    </Section>
  )
}

export default ServicePage
